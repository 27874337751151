

.navbar {
    color: black;
    padding: 10px;
  }
  
  .navbar h1 {
    display: inline;
    margin: 0;
    padding-right: 20px;
  }
  
  .navbar ul {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar ul li {
    display: inline;
    margin-right: 10px;
  }
  
  .navbar ul li a {
    color: black;
    text-decoration: none;
  }
  
  .navbar ul li a:hover {
    text-decoration: underline;
  }