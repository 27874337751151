h2 {
    color: black;
}

.tool-container {
    display: flex;
    padding: 20px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 75%;
    text-align: center;
    justify-content: space-between;
}
  
.tool-container input {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.tool-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

.tool-container button:hover {
    background-color: #0056b3;  
}

#results {
    margin-top: 20px;
    text-align: left;
}

.result-item {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.segmented-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.segment {
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-family: monospace;
}

.segment.highlight {
    background-color: yellow;
    font-weight: bold;
}

.buffstat-input, .ignore-input {
    width: 50px;
    max-width: 50px; 
    text-align: center; 
}
.tool-container {
    display: flex;
    flex-direction: row;
}

.input-group {
    margin-bottom: 10px;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px;
    background-color: #f9f9f9;
}

.buffstat-input {
    width: 100%;
}

.segmented-container {
    margin-top: 20px;
}

.segment {
    display: inline-block;
    margin: 0 5px;
}

.highlight {
    background-color: yellow;
}

.draggable-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.draggable-container span {
    font-weight: bold;
}

.calculate-button {
    width: 128px;
    height: 36px;
}

.top-tool-container {
    display:flex;
}

.textarea-fixed {
    width: 500px;
    height: 140px;
    resize: none; /* Prevent resizing */
}

.bottom-tool-container {
    display: flex;
    align-items: center;
    padding: 0 50px;
}
