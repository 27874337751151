/* ImageConverter.css */
.converter-container {
    text-align: center;
    padding: 20px;
}

.results {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.result-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.result-item img {
    max-width: 200px;
    max-height: 200px;
    border: 1px solid #ccc;
    padding: 5px;
    background-color: #f9f9f9;
}

.result-item textarea {
    width: 200px;
    font-family: monospace;
    font-size: 12px;
}
